import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import moment from 'moment'
import { ballotStore } from '@/services/Ballot/store'

export const methods = {
  determineRows: function(list) {
    const dateFormat = 'LL'

    let filteredList = list

    var rows = filteredList.map(entry => {
      const description = _get(entry, 'description', '')
      var date = _get(entry, 'completeByDate', '')
      const submittedVoteCountByUnit = _get(entry, 'submittedBallotCount', 0)

      try {
        date = moment(date).format(dateFormat)
      } catch (exception) {
        console.error(exception)
      }

      return {
        description,
        date: {
          date,
          format: dateFormat
        },

        details: {
          component: Button,
          props: {
            onClick: () => {
              this.processVote(entry)
            },
            text: submittedVoteCountByUnit > 0 ? 'View' : 'Vote',
            isprimary: submittedVoteCountByUnit > 0 ? false : true
          }
        }
      }
    })

    this.rows = rows
  },

  async reload() {
    console.debug('in reload...')
    this.loading = true

    await ballotStore
      .dispatch('getBallotList', {
        hoaID: this.currentHoaId,
        publishedAndCompleteBy: true,
        unitID: this.currentUnitId
      })
      .then(({ list }) => {
        this.determineRows(list)
        this.loading = false
      })
  },

  processVote(ballot) {
    if (ballot) {
      this.selectedBallot = ballot
      this.toggle = true
    }
  }
}
