<template>
  <PageContent>
    <div class="level pl-2">
      <div class="level-left level-item title">{{ $t('evotingList.title') }}</div>
    </div>
    <div>
      <ModernTable
        :region="region"
        :filters="filters"
        :rows="rows"
        :columns="columns"
        :pagination="{
          perPage: 12
        }"
        :loading="loading"
      />
    </div>
    <Modal :toggle.sync="toggle">
      <VoteModal :ballot="selectedBallot"> </VoteModal>
    </Modal>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import VoteModal from './components/voteModal'
import Modal from '@/components/Modal'
import { mapState, mapGetters } from 'vuex'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'EvotingList',
  components: {
    PageContent,
    ModernTable,
    VoteModal,
    Modal
  },

  computed: {
    ...mapState({
      ...mapGetters('user', ['authUser', 'currentHoaId', 'currentUnitId', 'currentOwnerId']),
      breakpoint: state => (state && state.win ? state.win.breakpoint : null)
    })
  },

  data,

  methods,

  async mounted() {
    await this.reload()
  },

  watch: {
    currentUnitId: 'reload',
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  i18n: {
    messages: {
      en: {
        evotingList: {
          title: 'eVoting'
        }
      }
    }
  }
}
</script>

<style></style>
