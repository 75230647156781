var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section",attrs:{"ballot-vote-modal":"","id":"ballotResponseModal"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"is-size-4"},[_vm._v("Ballot Voting")]),(_vm.ballotHold && _vm.ballotHold.title)?_c('div',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(("" + (_vm.ballotHold.title)))+" ")]):_vm._e(),(_vm.ballotHold && _vm.ballotHold.description)?_c('div',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(("" + (_vm.ballotHold.description)))+" ")]):_vm._e(),(
        _vm.ballotHold &&
          _vm.ballotHold.ballotType === 'multiple-choice' &&
          _vm.ballotHold.multipleChoiceNumOptionsSelectable
      )?_c('div',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(("Select up to " + (_vm.ballotHold.multipleChoiceNumOptionsSelectable || 0) + " option(s)"))+" ")]):_vm._e(),(
        _vm.ballotHold &&
          _vm.ballotHold.ballotType &&
          _vm.ballotHold.numOfElectionPositions &&
          _vm.ballotHold.ballotType === 'election'
      )?_c('div',{staticClass:"is-size-6 pb-5"},[_vm._v(" "+_vm._s(("Cast no more than " + (_vm.ballotHold.numOfElectionPositions) + " vote(s) for the election candidates"))+" ")]):_vm._e(),_c('div',{staticClass:"box is-12"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var handleSubmit = ref.handleSubmit;
return [_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmVoteConfirmation)}}},[_c('fieldset',[(_vm.ballotHold && _vm.ballotHold.ballotType === 'yes-no')?_c('div',[_c('b-field',[_c('b-radio',{attrs:{"name":"yesNo","native-value":"yes","disabled":_vm.ballotResponseTemp !== null},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v(" "+_vm._s(_vm.ballotHold.yesOptionDescription)+" ")])],1),_c('b-field',[_c('b-radio',{attrs:{"name":"yesNo","native-value":"no","disabled":_vm.ballotResponseTemp !== null},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v(" "+_vm._s(_vm.ballotHold.noOptionDescription)+" ")])],1)],1):_vm._e(),(_vm.ballotHold && _vm.ballotHold.ballotType === 'election')?_c('div',[(
                    _vm.ballotHold.electionVotingIsCumulative &&
                      _vm.ballotHold.electionVotingIsCumulative === true
                  )?_c('div',_vm._l((_vm.ballotHold.ballotCandidates),function(c,index){return _c('div',{key:index,staticClass:"block column is-12"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_vm._v(" "+_vm._s(c.isWriteIn != null && c.isWriteIn === true ? ((c.name) + " (Write-In)") : c.name)+" ")]),_c('div',{staticClass:"column"},[_c('b-numberinput',{attrs:{"min":"0","max":parseInt(_vm.ballotHold.numOfElectionPositions),"name":("candidateCheckboxGroup" + index),"vid":("candidateCheckboxGroup" + index),"id":("" + (c.ballotCandidateID)),"disabled":_vm.ballotResponseTemp !== null ? '' : false},model:{value:(_vm.candidateCheckboxGroup[index]),callback:function ($$v) {_vm.$set(_vm.candidateCheckboxGroup, index, $$v)},expression:"candidateCheckboxGroup[index]"}})],1),_c('div',{staticClass:"column is-3"},[(c.resumeDocumentID)?_c('span',[_c('b-button',{attrs:{"type":"is-text"},on:{"click":function($event){$event.preventDefault();return _vm.downloadPdf(c.ballotCandidateID)}}},[_vm._v("View Resume PDF")])],1):_vm._e()])])])}),0):_c('div',_vm._l((_vm.ballotHold.ballotCandidates),function(c,index){return _c('div',{key:index},[_c('b-field',[_c('span',{staticClass:"columns is-12"},[_c('span',{staticClass:"column is-6"},[_c('b-checkbox',{attrs:{"native-value":c.ballotCandidateID,"disabled":_vm.ballotResponseTemp !== null},model:{value:(_vm.candidateRadioGroup),callback:function ($$v) {_vm.candidateRadioGroup=$$v},expression:"candidateRadioGroup"}},[_vm._v(" "+_vm._s(c.isWriteIn != null && c.isWriteIn === true ? ((c.name) + " (Write-In)") : c.name)+" ")])],1),(c.resumeDocumentID)?_c('span',{staticClass:"column is-3"},[_c('b-button',{attrs:{"type":"is-text"},on:{"click":function($event){$event.preventDefault();return _vm.downloadPdf(c.ballotCandidateID)}}},[_vm._v("View Resume PDF")])],1):_vm._e()])])],1)}),0),(
                    _vm.ballotHold &&
                      _vm.ballotHold.writeInCandidatesEnabled !== undefined &&
                      _vm.ballotHold.writeInCandidatesEnabled === true
                  )?_c('div',{staticClass:"columns is-12"},[_c('div',{staticClass:"column is-6 pt-5"},[_c('valid-input',{staticClass:"is-small",staticStyle:{"width":"50px"},attrs:{"name":"WriteInName","label":"Write-In Name","type":"text","vid":"WriteInName","placeholder":"Write-In Name","spellcheck":"true","aria-label":"Write-In Name","rules":"max:50","disabled":_vm.ballotResponseTemp !== null,"editable":_vm.ballotResponseTemp !== null},model:{value:(_vm.writeInName),callback:function ($$v) {_vm.writeInName=$$v},expression:"writeInName"}})],1),_c('div',{staticClass:"column is-6 pt-6"},[_c('button',{staticClass:"button is-info",attrs:{"disabled":_vm.ballotResponseTemp !== null,"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addWriteIn()}}},[_vm._v(" Add ")])])]):_vm._e()]):_vm._e(),(_vm.ballotHold && _vm.ballotHold.ballotType === 'multiple-choice')?_c('div',_vm._l((_vm.ballotHold.ballotMultipleChoiceOptions),function(c,index){return _c('div',{key:index,staticClass:"block column is-12"},[_c('b-checkbox',{attrs:{"native-value":c.ballotMultipleChoiceOptionID,"disabled":_vm.ballotResponseTemp !== null},model:{value:(_vm.multipleChoiceCheckboxGroup),callback:function ($$v) {_vm.multipleChoiceCheckboxGroup=$$v},expression:"multipleChoiceCheckboxGroup"}},[_vm._v(" "+_vm._s(c.description)+" ")])],1)}),0):_vm._e(),(!_vm.ballotResponseTemp)?_c('div',{staticClass:"pt-6"},[_c('button',{staticClass:"button is-primary is-rounded",attrs:{"disabled":_vm.disableVoteButton,"type":"submit"}},[_vm._v(" Vote ")])]):_c('div',{staticClass:"pt-6"},[_c('button',{staticClass:"button is-primary is-rounded",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.closeModal()}}},[_vm._v(" Close ")])])]),_c('fieldset',[_c('ValidationProvider',{attrs:{"vid":"providerErrors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }