import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  toggle: false,
  selectedBallot: null,
  selectedBallotID: 0,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'description',
      label: 'Description',
      aria: 'Description',
      width: '80%',
      sortable: true,
      searchable: true
    },
    {
      field: 'date',
      label: 'Complete By',
      aria: 'Complete By',
      width: '20%',
      date: true,
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
