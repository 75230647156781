<template>
  <div ballot-vote-modal id="ballotResponseModal" class="section">
    <div class="container">
      <div class="is-size-4">Ballot Voting</div>
      <div class="is-size-6" v-if="ballotHold && ballotHold.title">
        {{ `${ballotHold.title}` }}
      </div>
      <div class="is-size-6" v-if="ballotHold && ballotHold.description">
        {{ `${ballotHold.description}` }}
      </div>
      <div
        class="is-size-6"
        v-if="
          ballotHold &&
            ballotHold.ballotType === 'multiple-choice' &&
            ballotHold.multipleChoiceNumOptionsSelectable
        "
      >
        {{ `Select up to ${ballotHold.multipleChoiceNumOptionsSelectable || 0} option(s)` }}
      </div>
      <div
        class="is-size-6 pb-5"
        v-if="
          ballotHold &&
            ballotHold.ballotType &&
            ballotHold.numOfElectionPositions &&
            ballotHold.ballotType === 'election'
        "
      >
        {{
          `Cast no more than ${ballotHold.numOfElectionPositions} vote(s) for the election candidates`
        }}
      </div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(confirmVoteConfirmation)" class="form">
              <fieldset>
                <div v-if="ballotHold && ballotHold.ballotType === 'yes-no'">
                  <b-field>
                    <b-radio
                      v-model="radio"
                      name="yesNo"
                      native-value="yes"
                      :disabled="ballotResponseTemp !== null"
                    >
                      {{ ballotHold.yesOptionDescription }}
                    </b-radio>
                  </b-field>
                  <b-field>
                    <b-radio
                      v-model="radio"
                      name="yesNo"
                      native-value="no"
                      :disabled="ballotResponseTemp !== null"
                    >
                      {{ ballotHold.noOptionDescription }}
                    </b-radio>
                  </b-field>
                </div>
                <div v-if="ballotHold && ballotHold.ballotType === 'election'">
                  <div
                    v-if="
                      ballotHold.electionVotingIsCumulative &&
                        ballotHold.electionVotingIsCumulative === true
                    "
                  >
                    <div
                      class="block column is-12"
                      v-for="(c, index) in ballotHold.ballotCandidates"
                      :key="index"
                    >
                      <div class="columns">
                        <div class="column is-4">
                          {{
                            c.isWriteIn != null && c.isWriteIn === true
                              ? `${c.name} (Write-In)`
                              : c.name
                          }}
                        </div>
                        <div class="column">
                          <b-numberinput
                            min="0"
                            :max="parseInt(ballotHold.numOfElectionPositions)"
                            :name="`candidateCheckboxGroup${index}`"
                            :vid="`candidateCheckboxGroup${index}`"
                            :id="`${c.ballotCandidateID}`"
                            v-model="candidateCheckboxGroup[index]"
                            :disabled="ballotResponseTemp !== null ? '' : false"
                          />
                        </div>
                        <div class="column is-3">
                          <span v-if="c.resumeDocumentID">
                            <b-button
                              @click.prevent="downloadPdf(c.ballotCandidateID)"
                              type="is-text"
                              >View Resume PDF</b-button
                            >
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-for="(c, index) in ballotHold.ballotCandidates" :key="index">
                      <b-field>
                        <span class="columns is-12">
                          <span class="column is-6">
                            <b-checkbox
                              v-model="candidateRadioGroup"
                              :native-value="c.ballotCandidateID"
                              :disabled="ballotResponseTemp !== null"
                            >
                              {{
                                c.isWriteIn != null && c.isWriteIn === true
                                  ? `${c.name} (Write-In)`
                                  : c.name
                              }}
                            </b-checkbox>
                          </span>
                          <span class="column is-3" v-if="c.resumeDocumentID"
                            ><b-button
                              @click.prevent="downloadPdf(c.ballotCandidateID)"
                              type="is-text"
                              >View Resume PDF</b-button
                            ></span
                          >
                        </span>
                      </b-field>
                    </div>
                  </div>
                  <div
                    class="columns is-12"
                    v-if="
                      ballotHold &&
                        ballotHold.writeInCandidatesEnabled !== undefined &&
                        ballotHold.writeInCandidatesEnabled === true
                    "
                  >
                    <div class="column is-6 pt-5">
                      <valid-input
                        style="width:50px"
                        name="WriteInName"
                        label="Write-In Name"
                        type="text"
                        vid="WriteInName"
                        placeholder="Write-In Name"
                        spellcheck="true"
                        aria-label="Write-In Name"
                        rules="max:50"
                        class="is-small"
                        v-model="writeInName"
                        :disabled="ballotResponseTemp !== null"
                        :editable="ballotResponseTemp !== null"
                      />
                    </div>
                    <div class="column is-6 pt-6">
                      <button
                        :disabled="ballotResponseTemp !== null"
                        @click.prevent="addWriteIn()"
                        type="button"
                        class="button is-info"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="ballotHold && ballotHold.ballotType === 'multiple-choice'">
                  <div
                    class="block column is-12"
                    v-for="(c, index) in ballotHold.ballotMultipleChoiceOptions"
                    :key="index"
                  >
                    <b-checkbox
                      v-model="multipleChoiceCheckboxGroup"
                      :native-value="c.ballotMultipleChoiceOptionID"
                      :disabled="ballotResponseTemp !== null"
                    >
                      {{ c.description }}
                    </b-checkbox>
                  </div>
                </div>
                <div v-if="!ballotResponseTemp" class="pt-6">
                  <button
                    :disabled="disableVoteButton"
                    type="submit"
                    class="button is-primary is-rounded"
                  >
                    Vote
                  </button>
                </div>
                <div v-else class="pt-6">
                  <button
                    type="button"
                    class="button is-primary is-rounded"
                    @click.prevent="closeModal()"
                  >
                    Close
                  </button>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { dragscroll } from 'vue-dragscroll'

// components
import ValidInput from '@/components/inputs/ValidInput'

export default {
  name: 'BallotResponseModal',
  props: {
    ballot: Object
  },

  directives: { dragscroll },

  components: {
    ValidationObserver,
    ValidationProvider,
    ValidInput
  },

  watch: {
    writeInName() {
      if (this.writeInName && this.writeInName.length > 0) {
        this.disableVoteButton = true
      } else {
        this.disableVoteButton = false
      }
    }
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      ...mapGetters('user', ['authUser', 'currentHoaId', 'currentUnitId', 'currentOwnerId'])
    })
  },

  data,
  methods
}
</script>
<style lang="scss"></style>
