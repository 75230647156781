/*
  import { ballotStore } from '@/services/Ballot/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await ballotStore.dispatch ('getBallotList', {
      hoaId
    });
  */

  async getBallotTypeList({}) {
    try {
      let ballotTypes = [
        { value: 'yesno', label: 'Yes / No' },
        { value: 'election', label: 'Election' },
        { value: 'multiplechoice', label: 'Multiple Choice' }
      ]

      if (isDebug == true) console.debug('ballotTypes=' + JSON.stringify(ballotTypes))

      return {
        list: ballotTypes
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the ballot type list.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getBallotList({}, { hoaID, publishedAndCompleteBy, unitID }) {
    console.log('in getBallotList...' + unitID)
    try {
      let path = `/EVoting/Ballot/List?hoaID=${hoaID}&publishedAndCompleteBy=${publishedAndCompleteBy}`
      if (unitID) {
        path = `${path}&unitID=${unitID}`
      }
      const results = await kms.get(path)

      if (isDebug == true) console.debug('getBallotList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyError(`The ballot list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async deleteBallot({ dispatch }, { ballotID, done }) {
    try {
      const path = `/EVoting/Ballot/${ballotID}?forceDelete=false`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted this ballot.`)
        done()
      } else {
        notifyError('There was a problem deleting this ballot.')
      }
    } catch (exception) {
      notifyError(`There was a problem deleting this ballot.`)
      console.error(exception)
    }
  },

  async getBallotById({}, { ballotID, unitID }) {
    try {
      console.debug('in getBallotById...')
      let path = `/EVoting/Ballot/${ballotID}`

      if (unitID) {
        path = `${path}?unitID=${unitID}`
      }

      const result = await kms.get(path)

      if (isDebug == true) console.debug('getBallotById=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      notifyError(`There was a problem retrieving this ballot detail.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async addBallot({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in addBallot')
    try {
      const result = await kms.post(`/EVoting/Ballot`, {
        hoaID: payload.hoaID,
        description: payload.description,
        fullDescription: payload.fullDescription,
        title: payload.title,
        ballotType: payload.ballotType,
        yesOptionDescription: payload.yesOptionDescription,
        noOptionDescription: payload.noOptionDescription,
        multipleChoiceNumOptionsSelectable: payload.multipleChoiceNumOptionsSelectable,
        electionVotingIsCumulative: payload.electionVotingIsCumulative,
        numOfElectionPositions: payload.numOfElectionPositions,
        completeByDate: payload.completeByDate,
        isSecret: payload.isSecret,
        writeInCandidatesEnabled: payload.writeInCandidatesEnabled
      })

      console.debug('results=' + JSON.stringify(result))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (result) {
        notifyMessage(`Successfully added this ballot.`)
        done({ detail: result })
      } else {
        notifyError('There was a problem adding this ballot.')
      }
    } catch (exception) {
      notifyError(`There was a problem adding this ballot.`)
      console.error(exception)
    }
  },

  // Update Ballot
  async updateBallot({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/EVoting/Ballot`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.ballotID > 0) {
        notifyMessage(`Successfully updated this ballot.`)
        done()
      } else {
        notifyError('There was a problem updating this ballot.')
      }
    } catch (exception) {
      notifyError(`There was a problem processing the update for this ballot.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const ballotStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
