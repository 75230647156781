import { ballotResponseStore } from '@/services/BallotResponse/store'
import { ballotCandidateStore } from '@/services/BallotCandidate/store'
import { ballotStore } from '@/services/Ballot/store'
import { notifyError, notifyMessage, notifyWarning, notifyProblem } from '@/services/notify'
import $ from 'jquery'
import _get from 'lodash/get'
import { base64toBlob, showPdf } from '@/services/blobutility'
import kms from '@/services/kms'

export const methods = {
  async reload() {
    console.debug('in reload()')

    this.ballotID = _get(this, 'ballot.ballotID', null)

    await this.loadBallotResponses().then(async () => {
      await this.loadBallotByID()
    })

    if (
      this.ballotHold.writeInCandidatesEnabled === undefined ||
      (this.ballotHold.writeInCandidatesEnabled !== undefined &&
        this.ballotHold.writeInCandidatesEnabled === false)
    ) {
      console.debug('writeInCandidatesEnabled=' + this.ballotHold.writeInCandidatesEnabled)
      this.ballotHold.ballotCandidates = this.ballotHold.ballotCandidates.filter(
        f => f.isWriteIn !== true
      )
    }

    this.candidateCheckboxGroup = []
    this.candidateRadioGroup = []
    this.multipleChoiceCheckboxGroup = []
    this.responseCandidates = []
    this.responseMultipleChoices = []

    let initNumeric = true

    if (this.ballotResponse) {
      console.debug('responses.......' + JSON.stringify(this.ballotResponseTemp))
      if (this.ballotResponseTemp === null) {
        this.ballotResponseTemp = this.ballotResponse
        console.debug('ballotResponseTemp=' + JSON.stringify(this.ballotResponseTemp))
      }

      const parsedVoteDataJson = JSON.parse(this.ballotResponseTemp.voteDataJson)

      const tempBallotType = _get(this.ballotHold, 'ballotType', '')

      if (parsedVoteDataJson) {
        console.debug('parsedVoteDataJson=' + JSON.stringify(parsedVoteDataJson))

        if (tempBallotType === 'yes-no') {
          console.debug('parsedVoteDataJson=' + parsedVoteDataJson.yesNoResponse)
          this.radio = parsedVoteDataJson.yesNoResponse
        } else if (tempBallotType === 'election') {
          if (
            parsedVoteDataJson.responseCandidates &&
            typeof parsedVoteDataJson.responseCandidates === 'object'
          ) {
            this.responseCandidates = parsedVoteDataJson.responseCandidates

            console.debug('this.responseCandidates=' + JSON.stringify(this.responseCandidates))

            if (this.responseCandidates) {
              this.responseCandidates.forEach(e => {
                if (e) {
                  initNumeric = false
                  this.candidateRadioGroup.push(e.ballotCandidateID)
                }
              })
            }
          }
        } else if (tempBallotType === 'multiple-choice') {
          if (
            parsedVoteDataJson.multipleChoiceOptions &&
            typeof parsedVoteDataJson.multipleChoiceOptions === 'object'
          ) {
            this.responseMultipleChoices = parsedVoteDataJson.multipleChoiceOptions
            this.responseMultipleChoices.forEach(e => {
              if (e) {
                this.multipleChoiceCheckboxGroup.push(e.ballotMultipleChoiceOptionID)
              }
            })
          }
        }
      }

      if (initNumeric === true) {
        await this.initializeNumeric()
      } else {
        await this.initializeNumericEdit()
      }

      if (this.ballotResponseTemp.ballotDocumentID) {
        this.attachment = true
        this.loadCurrentDocument()
      }
    } else {
      //'Add'
      await this.initializeNumeric()
    }
  },

  disableVoteForWriteIn() {},

  async initializeNumericEdit() {
    //Note: if this needs to be more precise then use jquery selector on id via ballotCandidateID

    //initialize numeric inputs
    if (
      this.ballotHold &&
      this.ballotHold.ballotCandidates &&
      this.ballotHold.ballotCandidates.length > 0
    ) {
      console.debug('in numeric initialize...')
      let counter = 0
      this.ballotHold.ballotCandidates.forEach(e => {
        if (e) {
          if (this.responseCandidates) {
            this.responseCandidates.forEach(r => {
              if (r) {
                if (r.ballotCandidateID === e.ballotCandidateID) {
                  this.candidateCheckboxGroup[counter] = r.numVotes
                } else if (!this.candidateCheckboxGroup[counter]) {
                  this.candidateCheckboxGroup[counter] = 0
                }
              }
            })
          }

          counter++
        }
      })
    }
  },

  async initializeNumeric() {
    //initialize numeric inputs
    if (
      this.ballotHold &&
      this.ballotHold.ballotCandidates &&
      this.ballotHold.ballotCandidates.length > 0
    ) {
      console.debug('in initializeNumericEdit...')
      let counter = 0
      this.ballotHold.ballotCandidates.forEach(e => {
        if (e) {
          this.candidateCheckboxGroup[counter] = 0
          counter++
        }
      })
    }
  },

  async confirmVoteConfirmation() {
    this.$buefy.dialog.confirm({
      title: 'Confirmation of Vote',
      message: `<p>I acknowledge that I am the registered owner and authorized to cast this vote.</p><p>&nbsp;</p><p>${this.ballotHold.ballotStatuteDisclaimer}</p>`,
      confirmText: 'Confirm',
      type: 'is-info',
      hasIcon: true,
      onConfirm: async () => await this.onFormSubmit()
    })
  },

  async downloadPdf(ballotCandidateID) {
    if (this.isDebug == true) console.debug('downloadPdf...')

    if (!ballotCandidateID || ballotCandidateID == undefined) {
      notifyProblem('There was a problem downloading this candidate resume.')
    }

    try {
      const params = {
        ballotCandidateID: ballotCandidateID,
        asBase64: true,
        asRawBase64: true
      }

      const path = `/EVoting/BallotCandidate/GetFile`
      const returned = await kms.get(path, {
        params
      })

      if (this.isDebug == true)
        console.debug(
          'path base64 path=' +
            path +
            ' - params' +
            JSON.stringify(params) +
            ' - returned=' +
            JSON.stringify(returned)
        )
      if (returned && returned != undefined) {
        if (this.isDebug == true) console.debug('base64pdf........................' + returned)
        const contentType = 'application/pdf'
        const blob = base64toBlob(returned, contentType)
        showPdf(blob)
        notifyMessage(`Successfully downloaded this candidate's resume.`)
        return
      }
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading this resume.')
    }
  },

  async loadBallotResponses() {
    const ballotID = this.ballotID

    console.log('loadBallotResponses?')

    const unitID = this.currentUnitId
      ? this.currentUnitId
      : _get(this, 'authUser.ownerUser.unitID', null)

    await ballotResponseStore
      .dispatch('getBallotResponseList', {
        ballotID,
        unitID
      })
      .then(({ list }) => {
        if (list) {
          this.ballotResponses = list
        }
      })
  },

  async loadBallotByID() {
    console.debug('in loadBallotByID')
    await ballotStore
      .dispatch('getBallotById', {
        ballotID: this.ballotID,
        unitID: this.currentUnitId
      })
      .then(async ({ result }) => {
        if (result) {
          let response = null

          console.debug('in loadBallotByID...' + JSON.stringify(this.ballotResponses))

          let tempBallotType = _get(result, 'ballotType', '')
          if (tempBallotType === 'yes-no') {
            this.ballotType = 'Yes / No'
            console.debug('yes-no ballot response=')

            if (this.ballot && this.ballotResponses) {
              response = this.ballotResponses.filter(
                w =>
                  w.unitID === this.currentUnitId &&
                  w.ownerID === this.currentOwnerId &&
                  w.voteDataJson.indexOf('yesNoResponse') >= 0
              )

              console.debug('yes-no ballot response=' + JSON.stringify(response))
            }
          } else if (tempBallotType === 'multiple-choice') {
            this.ballotType = 'Multiple Choice'
            if (this.ballot && this.ballotResponses) {
              response = this.ballotResponses.filter(
                w =>
                  w.unitID === this.currentUnitId &&
                  w.ownerID === this.currentOwnerId &&
                  w.voteDataJson.indexOf('multipleChoiceOptions') >= 0
              )
            }
          } else if (tempBallotType === 'election') {
            this.ballotType = 'Election'
            if (this.ballot && this.ballotResponses) {
              response = this.ballotResponses.filter(
                w =>
                  w.unitID === this.currentUnitId &&
                  w.ownerID === this.currentOwnerId &&
                  w.voteDataJson.indexOf('responseCandidates') >= 0
              )
            }
          } else {
            this.ballotType = ''
          }

          this.ballotHold = result

          if (response) {
            this.ballotResponse = response[0]
            console.debug('response to ballotResponse...' + JSON.stringify(this.ballotResponse))
          }

          console.debug('loadBallotByID=' + JSON.stringify(this.ballotHold))
        }
      })
  },

  async loadCurrentDocument() {
    if (
      !this.ballotResponseTemp ||
      !this.ballotResponseTemp.ballotResponseID ||
      this.ballotResponseTemp.ballotResponseID <= 0
    ) {
      return
    }

    try {
      const params = {
        ballotResponseID: this.ballotResponseTemp.ballotResponseID,
        asBase64: true
      }

      await ballotResponseStore.dispatch('getResponseFile', {
        params: params,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true)
              console.debug(
                ' - params' + JSON.stringify(params) + ' - this.getResponseFile=' + details
              )

            this.base64pdf = details
            this.imageContent = null
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
    }
  },

  async submitFile() {
    if (!this.ballotResponseID && this.ballotResponseID <= 0) {
      notifyError('There was a problem uploading this document.')
      return
    }

    this.loading = true

    try {
      if (this.file) {
        const payload = {
          ballotResponseID: this.ballotResponseID,
          file: this.file
        }

        await ballotResponseStore.dispatch('uploadDocument', {
          payload: payload,
          done: ({ returnedFile }) => {
            if (returnedFile) {
              notifyMessage(`Successfully uploaded this response document.`)
            } else {
              notifyError('There was a problem uploading this response document.')
            }
          }
        })
      }
    } catch (e) {
      notifyError(e)
    }
    this.loading = false
  },

  async getBallotResponseById() {
    await ballotResponseStore
      .dispatch('getBallotResponseById', {
        ballotResponseID: this.ballotResponseID,
        unitID: this.currentUnitId
          ? this.currentUnitId
          : _get(this.authUser.ownerUser, 'unitID', null)
      })
      .then(async ({ result }) => {
        if (result) {
          this.ballotResponseTemp = result
        }
      })
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async onFormSubmit() {
    if (!this.formData || (this.formData === undefined && this.ballotHold)) {
      notifyError('There was a problemm saving this response.')
      return
    }

    console.debug('ballotType=' + this.ballotHold.ballotType)

    let tempVoteJson = ''
    if (this.ballotHold.ballotType === 'yes-no' && this.radio) {
      tempVoteJson = {
        yesNoResponse: this.radio
      }
    } else if (this.ballotHold.ballotType === 'election') {
      console.debug(
        'election electionVotingIsCumulative=' + this.ballotHold.electionVotingIsCumulative
      )

      if (
        (!this.ballotHold.electionVotingIsCumulative ||
          this.ballotHold.electionVotingIsCumulative === false) &&
        this.candidateRadioGroup &&
        this.candidateRadioGroup.length > 0
      ) {
        console.debug('non-cumulative election....')
        console.debug('candidate radio group=' + this.candidateRadioGroup)

        //Validate open positions
        if (
          this.ballotHold.numOfElectionPositions > 0 &&
          this.ballotHold.numOfElectionPositions !== this.candidateRadioGroup.length
        ) {
          this.candidateRadioGroup = []
          notifyError(
            `Cast no more or less than ${this.ballotHold.numOfElectionPositions || 0} vote(s).`
          )
          return
        }

        let checkedNonCumulatives = []
        if (this.candidateRadioGroup && this.candidateRadioGroup.length > 0) {
          this.candidateRadioGroup.forEach(e => {
            if (e) {
              checkedNonCumulatives.push({ ballotCandidateID: e, numVotes: 1 })
            }
          })
        }

        tempVoteJson = {
          responseCandidates: checkedNonCumulatives
        }
      } else {
        console.debug('cumulative election...........')
        console.debug('candidate radio=' + this.candidateCheckboxGroup)

        const summedLength = this.candidateCheckboxGroup.reduce((acc, item) => acc + item, 0)

        console.debug(summedLength + ', Cumulative Sums=' + this.candidateCheckboxGroup)

        if (
          this.ballotHold.numOfElectionPositions > 0 &&
          this.ballotHold.numOfElectionPositions !== summedLength
        ) {
          notifyError(
            `Cast no more or less than ${this.ballotHold.numOfElectionPositions || 0} vote(s).`
          )
          return
        }

        let checkedResponseCandidates = []
        if (this.candidateCheckboxGroup && this.candidateCheckboxGroup.length > 0) {
          let cumulativeCounter = 0

          this.candidateCheckboxGroup.forEach(e => {
            if (e) {
              let selectedBallotCandidateID = _get(
                this.ballotHold.ballotCandidates[cumulativeCounter],
                'ballotCandidateID',
                null
              )

              if (selectedBallotCandidateID) {
                checkedResponseCandidates.push({
                  ballotCandidateID: selectedBallotCandidateID,
                  numVotes: e
                })
              }
            }
            cumulativeCounter++
          })
        }

        tempVoteJson = {
          responseCandidates: checkedResponseCandidates
        }
      }
    } else if (this.ballotHold.ballotType === 'multiple-choice') {
      let checkedMultipleChoiceOptions = []

      if (this.multipleChoiceCheckboxGroup && this.multipleChoiceCheckboxGroup.length > 0) {
        //Validate maximum selectable
        if (
          this.multipleChoiceCheckboxGroup.length >
          this.ballotHold.multipleChoiceNumOptionsSelectable
        ) {
          notifyError(
            `Please select up to ${this.ballotHold.multipleChoiceNumOptionsSelectable ||
              0} choice(s).`
          )
          return
        }

        this.multipleChoiceCheckboxGroup.forEach(e => {
          if (e) {
            checkedMultipleChoiceOptions.push({ ballotMultipleChoiceOptionID: e })
          }
        })
      }

      tempVoteJson = {
        multipleChoiceOptions: checkedMultipleChoiceOptions
      }
    }

    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    try {
      const payload = {
        ballotID: this.ballotID,
        unitID: this.currentUnitId,
        ownerID: this.currentOwnerId,
        ballotDocumentID: _get(this.ballotResponseTemp, 'ballotDocumentID', null),
        voteDataJson: JSON.stringify(tempVoteJson),
        isHomeOwner: true
      }

      console.debug('payload=' + JSON.stringify(payload))

      if (this.ballotResponseTemp && this.ballotResponseTemp !== undefined) {
        payload.ballotResponseID = this.ballotResponseTemp.ballotResponseID

        await ballotResponseStore.dispatch('updateBallotResponse', {
          payload,
          done: async ({ detail }) => {
            if (detail) {
              this.ballotResponseID = detail.ballotResponseID

              if (this.file && payload.ballotDocumentID == null) {
                this.submitFile()
              }

              this.closeModal()
            }
          }
        })
      } else {
        await ballotResponseStore.dispatch('addBallotResponse', {
          payload,
          done: async ({ detail }) => {
            if (detail) {
              console.debug('add candidate detail=' + JSON.stringify(detail))
              this.ballotResponseID = detail.ballotResponseID

              if (this.file) {
                this.submitFile()
              }

              this.closeModal()
            }
          }
        })
      }
    } catch (e) {
      notifyError(e)
    }
    loadingComponent.close()

    this.loading = false
  },

  async addWriteIn() {
    if (!this.writeInName || this.writeInName === undefined) {
      notifyError('The write-in candidate name is required.')
      return
    }

    if (this.writeInName.length > 50) {
      notifyError('Please enter only fifty characters for the write-in candidate.')
      return
    }

    this.loading = true

    this.disableVoteButton = false

    try {
      const payload = {
        ballotID: this.ballotID,
        name: this.writeInName,
        isWriteIn: true,
        writeInByUnitID: this.currentUnitId,
        resume: `Write-in Candidate for ${this.writeInName}`
      }

      console.log('payload=' + JSON.stringify(payload))

      await ballotCandidateStore.dispatch('addBallotCandidate', {
        payload,
        done: async ({ detail }) => {
          if (detail) {
            console.debug('write-in inserted...')
            await this.reload()
            this.writeInName = ''
          }
        }
      })
    } catch (e) {
      notifyError(e)
    }
    this.loading = false
  }
}
