export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    file: null,
    base64pdf: '',
    scale: 1,
    imageContent: null,
    attachment: false,
    ballotResponseID: 0,
    ballotResponseTemp: null,
    ballotResponse: null,
    ballotID: 0,
    ballotType: '',
    ballotHold: null,
    radio: '',
    candidateRadioGroup: [],
    candidateCheckboxGroup: [],
    multipleChoiceCheckboxGroup: [],
    formData: {},
    name: '',
    data: [],
    responseCandidates: [],
    responseMultipleChoices: [],
    ballotResponses: [],
    writeInName: '',
    disableVoteButton: false
  }
}
