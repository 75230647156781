/*
  import { ballotCandidateStore } from '@/services/BallotCandidate/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await ballotCandidateStore.dispatch ('getBallotCandidateList', {
      ballotID
    });
  */

  async getBallotCandidateList({}, { ballotID, includeWriteIns }) {
    console.log('in getBallotCandidateList...' + ballotID)
    try {
      const results = await kms.get(
        `/EVoting/BallotCandidate/List?ballotID=${ballotID}&includeWriteIns=${includeWriteIns}`
      )

      if (isDebug == true) console.debug('getBallotCandidateList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The ballot candidate list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getBallotCandidateById({}, { ballotCandidateID }) {
    try {
      console.debug('in getBallotCandidateById...')
      const result = await kms.get(`/EVoting/BallotCandidate/${ballotCandidateID}`)

      if (isDebug == true) console.debug('getBallotCandidateById=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving this ballot candidate.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async deleteBallotCandidate({ dispatch }, { ballotCandidateID, done }) {
    try {
      const path = `/EVoting/BallotCandidate/${ballotCandidateID}?forceDelete=false`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted this candidate.`)
        done()
      } else {
        notifyError('There was a problem deleting this candidate.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting this candidate.`)
      console.error(exception)
    }
  },

  async addBallotCandidate({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in addBallotCandidate')
    try {
      const results = await kms.post(`/EVoting/BallotCandidate`, {
        ballotID: payload.ballotID,
        name: payload.name,
        resume: payload.resume,
        writeInByUnitID: payload.writeInByUnitID,
        isWriteIn: payload.isWriteIn
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.ballotID > 0) {
        notifyMessage(`Successfully added this candidate.`)
        done({ detail: results })
      } else {
        notifyError('There was a problem adding this candidate.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this candidate.`)
      console.error(exception)
    }
  },

  // Update Ballot Candidate
  async updateBallotCandidate({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/EVoting/BallotCandidate`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.ballotCandidateID > 0) {
        notifyMessage(`Successfully updated this candidate.`)
        done({ detail: results })
      } else {
        notifyError('There was a problem updating this candidate.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this candidate.`)
      console.error(exception)
    }
  },

  async getResumeFile({ dispatch }, { params, done }) {
    const path = `/EVoting/BallotCandidate/GetFile`
    const result = await kms.get(path, {
      params
    })

    if (this.isDebug == true) console.debug('getResumeFile=' + JSON.stringify(result))

    if (dispatch && dispatch != undefined) console.debug(dispatch)

    if (result) {
      console.debug(`Successfully retrieved this candidat'e resume.`)
      done({ details: result })
    } else {
      console.error('There was a problem retrieving this resume.')
    }
  },

  async uploadDocument({ dispatch }, { payload = {}, done }) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/EVoting/BallotCandidate/StoreDocument?hoaID=${payload.hoaID}&ballotCandidateID=${payload.ballotCandidateID}`
      const resumeDocumentFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      console.debug('uploadDocument path=' + path)

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (isDebug == true)
        console.debug('uploadDocument with payload=' + JSON.stringify(resumeDocumentFile))

      done({ returnedFile: resumeDocumentFile })
    } catch (e) {
      notifyError(e)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const ballotCandidateStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
