/*
  import { ballotResponseStore } from '@/services/BallotResponse/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await ballotResponseStore.dispatch ('  async getBallotResponseList({}, { ballotID }) {
', {
      ballotID
    });
  */

  async getBallotResponseList({}, { ballotID, unitID }) {
    console.log('in getBallotResponseList...' + ballotID)
    try {
      let path = `/EVoting/BallotResponse/List?ballotID=${ballotID}`

      if (unitID) {
        path = `${path}&unitID=${unitID}`
      }

      const results = await kms.get(path)

      if (isDebug == true) console.debug('getBallotResponseList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The ballot response list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async deleteBallotResponse({ dispatch }, { ballotResponseID, done }) {
    try {
      const path = `/EVoting/BallotResponse/${ballotResponseID}?forceDelete=false`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted this ballot response.`)
        done()
      } else {
        notifyError('There was a problem deleting this ballot response.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting this ballot response.`)
      console.error(exception)
    }
  },

  async getBallotResponseById({}, { ballotResponseID, unitID }) {
    try {
      console.debug('in getBallotResponseById...')

      let path = `/EVoting/BallotResponse/${ballotResponseID}`
      if (unitID) {
        path = `${path}?unitID=${unitID}`
      }

      const result = await kms.get(path)

      if (isDebug == true) console.debug('getBallotResponseById=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving this ballot response.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async hasVotedonBallotResponseAlready({ dispatch }, { ballotID, ownerID, unitID, done }) {
    try {
      console.debug('in hasVotedonBallotResponseAlready...')

      const result = await kms.get('/EVoting/BallotResponse/HasVotedOnBallotResponseAlready', {
        params: {
          ballotID,
          ownerID,
          unitID
        }
      })

      if (dispatch && dispatch != undefined) console.debug(dispatch)
      if (isDebug == true)
        console.debug('hasVotedonBallotResponseAlready=' + JSON.stringify(result))

      done({ detail: result })
    } catch (exception) {
      notifyProblem(`There was a problem retrieving whether this ballot was voted on already.`)
      console.error(exception)
    }
  },

  async addBallotResponse({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in addBallotResponse')
    try {
      const results = await kms.post(`/EVoting/BallotResponse`, {
        ballotID: payload.ballotID,
        unitID: payload.unitID,
        ownerID: payload.ownerID,
        ballotDocumentID: payload.ballotDocumentID,
        voteDataJson: payload.voteDataJson,
        isHomeOwner: payload.isHomeOwner !== undefined && payload.isHomeOwner ? true : false
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.ballotResponseID > 0) {
        notifyMessage(`Successfully added this ballot response.`)
        done({ detail: results })
      } else {
        notifyError('There was a problem adding this ballot response.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this ballot response.`)
      console.error(exception)
    }
  },

  async updateBallotResponse({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/EVoting/BallotResponse`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.ballotResponseID > 0) {
        notifyMessage(`Successfully updated this ballot response.`)
        done({ detail: results })
      } else {
        notifyError('There was a problem updating this ballot response.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this ballot response.`)
      console.error(exception)
    }
  },

  async getResponseFile({ dispatch }, { params, done }) {
    const path = `/EVoting/BallotResponse/GetFile`
    const result = await kms.get(path, {
      params
    })

    if (this.isDebug == true) console.debug('getResponseFile=' + JSON.stringify(result))

    if (dispatch && dispatch != undefined) console.debug(dispatch)

    if (result) {
      console.debug(`Successfully retrieved this document.`)
      done({ details: result })
    } else {
      console.error('There was a problem retrieving this document.')
    }
  },

  async uploadDocument({ dispatch }, { payload = {}, done }) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/EVoting/BallotResponse/StoreDocument?ballotResponseID=${payload.ballotResponseID}`
      const ballotDocumentFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      console.debug('uploadDocument ballot response path=' + path)

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (isDebug == true)
        console.debug(
          'uploadDocument ballot resopnse with payload=' + JSON.stringify(ballotDocumentFile)
        )

      done({ returnedFile: ballotDocumentFile })
    } catch (e) {
      notifyError(e)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const ballotResponseStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
